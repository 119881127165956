<template>
  <draggable
    class="drag-ele"
    v-model="myArray"
    ghost-class="ghostClass"
    dragClass="bg-move"
    :forceFallback="true"
    @start="handleStart"
    @end="handleEnd"
    v-bind="{ group: 'title', animation: 150 }"
    @change="handleChange"
    draggable=".item"
  >
    <div
      class="item dp-flex justify-btw align-item-c pr"
      v-for="item in myArray"
      :key="item.keyName"
    >
      <p class="dp-flex align-item-c">
        <i class="el-icon-rank"></i>{{ item.name }}
      </p>
      <i
        class="el-icon-close pa"
        style="top:6px;right:10px;"
        @click="handleDel(item)"
      ></i>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: {
    initList: { type: Array, default: () => [] }
  },
  data() {
    return {
      drag: true,
      myArray: [],
      moveItem: {}
    };
  },
  methods: {
    handleStart(evt) {
      this.drag = true;
      // console.log("handleStart(evt)", evt);
      if (evt.originalEvent.target.className == "el-icon-close pa") {
        this.handleDel(this.myArray[evt.oldIndex]);
      }
    },
    handleEnd(evt) {
      this.drag = false;
    },
    handleChange(evt) {
      // console.log("handleChange(evt)", evt);
      // console.log("1-this.myArray", this.myArray);
      // console.log("1-this.myArray", this.myArray.map(i=>i.name));
      // console.log("1-this.initList", this.initList);
      // console.log("1-this.initList", this.initList.map(i=>i.name));
      this.$emit("sort-change", this.myArray);
    },
    handleDel(item) {
      this.$emit("del-item", item);
    }
  },
  watch: {
    initList: {
      handler(val) {
        this.myArray = [...val];
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/commonly";
.drag-ele {
  .item {
    padding: 6px 10px;
    border-bottom: 1px solid rgb(238, 238, 238);
  }
  .el-icon-rank {
    margin-right: 15px;
    cursor: move;
  }
  .bg-move {
    background-color: rgb(241, 247, 255);
  }
  .ghostClass {
    @extend .bg-theme;
    font-size: 0;
  }
}
</style>